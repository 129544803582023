@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 166 100% 50%;
    --primary-foreground: 0 0% 0%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
@font-face {
  font-family: "Code Mono";
  src: url("../public/assets/fonts/CodoMono-Regular.woff2") format("woff2"),
    url("../public/assets/fonts/CodoMono-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Code Mono";
  src: url("../public/assets/fonts/CodoMono-Bold.woff2") format("woff2"),
    url("../public/assets/fonts/CodoMono-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Code Mono";
  src: url("../public/assets/fonts/CodoMono-Medium.woff2") format("woff2"),
    url("../public/assets/fonts/CodoMono-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Space Mono";
  src: url("../public/assets/fonts/SpaceMono-Regular.woff2") format("woff2"),
    url("../public/assets/fonts/SpaceMono-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apercu Mono Pro";
  src: url("../public/assets/fonts/ApercuMonoPro-Medium.woff2") format("woff2"),
    url("../public/assets/fonts/ApercuMonoPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apercu Mono Pro";
  src: url("../public/assets/fonts/ApercuMonoPro-Light.woff2") format("woff2"),
    url("../public/assets/fonts/ApercuMonoPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apercu Mono Pro";
  src: url("../public/assets/fonts/ApercuMonoPro-Regular.woff2") format("woff2"),
    url("../public/assets/fonts/ApercuMonoPro-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apercu Mono Pro";
  src: url("../public/assets/fonts/ApercuMonoPro-Bold.woff2") format("woff2"),
    url("../public/assets/fonts/ApercuMonoPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

::-moz-selection {
  background: #00ffc2;
  color: #1b1b1b;
}
::selection {
  background: #00ffc2;
  color: #1b1b1b;
}

body {
  margin: 0;
  padding: 0;
  background-color: #303030;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  text-rendering: optimizeLegibility;
  min-height: 100%;
  color: white;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Code Mono";
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #242424;
}

*::-webkit-scrollbar-thumb {
  background-color: #000000;
}

p {
  font-size: 13px;
}
